// appearance for cast
export const appearance = [
  {
    id: 1,
    title: "綺麗系"
  },
  {
    id: 2,
    title: "清楚系"
  },
  {
    id: 3,
    title: "可愛い系"
  },
  {
    id: 4,
    title: "ギャル系"
  },
  {
    id: 5,
    title: "モデル系"
  },
  {
    id: 6,
    title: "その他"
  }
];

export const figure = [
  {
    id: 1,
    title: "スリム"
  },
  {
    id: 2,
    title: "普通"
  },
  {
    id: 3,
    title: "グラマー"
  }
];
