<template>
  <div id="view-profile-cast">
    <template v-if="isLoading">
      <AppLoading />
    </template>
    <div class="view-profile-cast" v-else>
      <CardHeader ref="header" :node="headerTitle" />
      <div id="content-scroll" class="content-scroll">
        <div
          class="user-summary text-center d-flex justify-content-center w-100"
        >
          <UserSummary
            :propsUser="user"
            :propsType="typeSingle"
            :propsSex="2"
            :propsButton="false"
            v-if="!isShowVideo && !isShowImage"
          />
          <div v-if="isShowVideo" class="position-relative video-player">
            <video
              :src="video_url"
              width="100%"
              height="100%"
              class=""
              id="video"
              controlslist="nodownload"
              :poster="image_url_two"
              preload="metadata"
              type="video/mp4"
              @click="playVideo()"
            ></video>
            <img
              src="@/assets/image/icon_profile/icon-play-video.svg"
              @click="playVideo()"
              alt=""
              class="play-video-active position-absolute"
              v-if="isShowIconPlayVideo"
            />
          </div>
          <div v-if="isShowImage" class="video-player">
            <img :src="image_full_url" class="w-100 h-100 image-full" />
          </div>
        </div>
        <div class="image row" v-if="imageDetail">
          <div class="image__div" @click="changeImage(image_url_one)">
            <img class="image__img img" :src="image_url_one" />
          </div>
          <!-- <div class="image__div" v-if="image_url_two">
            <img
              class="image__img"
              @click="changeImage(image_url_two)"
              :src="image_url_two"
            />
          </div> -->
          <div class="image__div position-relative" v-if="video_url">
            <video
              class="image__img"
              :src="video_url"
              controlslist="nodownload"
              preload="metadata"
              :poster="image_url_two"
              type="video/mp4"
              @click="showVideo()"
            />
            <img
              src="@/assets/image/icon_profile/icon-play-video.svg"
              @click="showVideo()"
              alt=""
              class="play-video position-absolute"
            />
          </div>
          <div
            class="image__div"
            v-for="(image, index) in imageDetail"
            :key="index"
            @click="changeFullImage(image.image_url)"
          >
            <img class="image__img img" :src="image.image_url" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="name text-left">
              <p class="f-w3 user-name">ニックネーム</p>
              <p class="f-w6 nick-name">{{ user.nickname }}</p>
              <span class="id__span f-w3">@{{ user.unique_id }}</span>
              <div class="name__div">
                <span class="name__span f-w3">{{ user.age }}歳</span>
                <span class="name__span f-w3" v-if="user.height"
                  >{{ user.height }}cm</span
                >
                <span class="name__span f-w3">{{ user.occupation }}</span>
              </div>
            </div>
            <div class="user-btn d-flex f-w3">
              <div
                class="user-btn-detail user-btn-beautiful"
                v-if="user.appearance"
              >
                {{ user.appearance }}
              </div>
              <div class="user-btn-detail user-btn-slender" v-if="user.figure">
                {{ user.figure }}
              </div>
              <div
                class="user-btn-detail user-btn-one-member"
                v-if="user.single_participant == 1"
              >
                一人参加OK
              </div>
              <div
                class="user-btn-detail user-btn-english"
                v-for="(other_preference, index) in user.other_preference"
                :key="index"
              >
                {{ other_preference }}
              </div>
            </div>
            <!-- <div class="rank">
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">総合ランク</p>
                <span class="rank__span text-right f-w6 col-6">
                  {{ user.overall_rank }}</span
                >
              </div>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">ゴルフレベル</p>
                <span class="rank__span text-right f-w6 col-6">
                  {{ user.female_golf_rank }}
                </span>
              </div>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">ランク</p>
                <span
                  class="rank__span text-right col-6 f-w6"
                  v-if="user.female_prego_rank == 'WHITE'"
                >
                  プレ友
                </span>
                <span
                  class="rank__span text-right col-6 f-w6"
                  v-else-if="user.female_prego_rank == 'BLUE'"
                >
                  新人
                </span>
                <span
                  class="rank__span text-right col-6 f-w6"
                  v-else-if="user.female_prego_rank == 'GREEN'"
                >
                  通常
                </span>
                <span
                  class="rank__span text-right col-6 f-w6"
                  v-else-if="user.female_prego_rank == 'RED'"
                >
                  超優良
                </span>
                <span class="rank__span text-right col-6 f-w6" v-else>
                  {{ user.female_prego_rank }}
                </span>
              </div>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">30分当たりの料金</p>
                <span class="rank__span text-right f-w6 col-6">
                  {{ Intl.NumberFormat().format(user["30_minutes_pay"]) }}P
                </span>
              </div>
            </div> -->
            <div class="biography">
              <p class="title text-left f-w6">自己紹介</p>
              <p class="biography__p f-w3 text-left">
                {{ user.biography }}
              </p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="rank rank__right">
              <p class="title text-left f-w6">基本情報</p>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">ピックアップエリア</p>
                <span class="rank__span text-right f-w6 col-6">
                  {{ user.prefecture }}
                </span>
              </div>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">ベストスコア</p>
                <span class="rank__span text-right f-w6 col-6">
                  {{ user.best_score >= 160 ? 160 : user.best_score }}
                </span>
              </div>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">アベレージ</p>
                <span class="rank__span text-right f-w6 col-6">{{
                  user.avg_score >= 180 ? 180 : user.avg_score
                }}</span>
              </div>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">ゴルフ歴</p>
                <span class="rank__span text-right f-w6 col-6">
                  {{ user.golf_experience }}
                </span>
              </div>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">年間ゴルフラウンド数</p>
                <span class="rank__span text-right f-w6 col-6">
                  {{ user.golf_frequency }}
                </span>
              </div>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">プレーエリア</p>
                <span class="rank__span text-right f-w6 col-6">
                  {{ user.area }}
                </span>
              </div>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">合流方法</p>
                <span
                  class="rank__span text-right f-w6 col-6"
                  v-if="user.meeting_method"
                >
                  {{ user.meeting_method }}
                </span>
              </div>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">交際</p>
                <span class="rank__span text-right f-w6 col-6">
                  {{ user.relationship }}
                </span>
              </div>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">タバコ</p>
                <span class="rank__span text-right f-w6 col-6">{{
                  user.cigarette
                }}</span>
              </div>
              <div class="rank__div row align-items-center">
                <p class="rank__p text-left f-w3 col-6">お酒</p>
                <span class="rank__span text-right f-w6 col-6">{{
                  user.alcohol
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="divide" v-if="user.round_logs.length > 0" />
        <div class="diary" v-if="user.round_logs.length > 0">
          <div
            class="d-flex diary-text justify-content-between align-items-center"
          >
            <p class="text-left diary-text__title f-w6 mb-0">ラウンドログ</p>
            <div
              class="d-flex align-items-center"
              style="cursor: pointer"
              @click="
                $router.push({
                  name: 'CastRoundLogList',
                  query: {
                    sex: 2
                  }
                })
              "
            >
              <!-- <p class="diary-text-more mb-0">もっと見る</p> -->
              <b-icon icon="chevron-right" />
            </div>
          </div>
          <div class="row diary__div--row">
            <div
              class="diary__div col-lg-6"
              v-for="(roundlog, index) in user.round_logs"
              :key="index"
            >
              <div class="heading d-flex justify-content-between">
                <div class="heading__div">
                  <p class="heading__p" />
                  <p class="heading__span f-w6 mb-0">{{ roundlog.nickname }}</p>
                </div>
                <div class="d-flex justify-content-end aligin-items-center">
                  <div
                    class="
                      heading__div heading__p--left
                      d-flex
                      flex-column
                      justify-content-center
                    "
                  >
                    <p class="f-w3 heading__p">ラウンドスコア</p>
                    <span class="heading__span f-w6">{{ roundlog.score }}</span>
                  </div>
                  <div
                    class="
                      heading__div
                      d-flex
                      flex-column
                      justify-content-center
                    "
                  >
                    <p class="f-w3 heading__p">満足度</p>
                    <span class="heading__span f-w6">{{
                      roundlog.evaluation
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="diary__div-text">
                <p class="f-w3 text-left diary__div-text__p mb-3">
                  {{
                    (index == 0 && !isShowFullText) ||
                    (index == 1 && !isShowFullTextBottom)
                      ? compactText(roundlog.comment)
                      : roundlog.comment
                  }}
                  <span
                    v-if="
                      ((index == 0 && !isShowFullText) ||
                        (index == 1 && !isShowFullTextBottom)) &&
                        roundlog.comment.length > getNumberCharacters()
                    "
                    class="show-text f-w3"
                    @click="showFullText(index)"
                    style="cursor: pointer"
                  >
                    詳細
                  </span>
                </p>
                <p
                  v-if="
                    !(
                      (index == 0 && !isShowFullText) ||
                      (index == 1 && !isShowFullTextBottom)
                    )
                  "
                  class="text-left show-text show-text-full f-w3"
                  @click="showFullText(index)"
                  style="cursor: pointer"
                >
                  たたむ
                </p>
              </div>
              <div
                class="divide--small survey"
                v-if="index < user.round_logs.length - 1"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="divide"
          v-if="firebaseProfileTweets && firebaseProfileTweets.length > 0"
        />
        <div
          class="diary"
          style="padding-bottom: 0"
          v-if="firebaseProfileTweets && firebaseProfileTweets.length > 0"
        >
          <div
            class="
              d-flex
              diary-text
              justify-content-between
              align-items-center
              mb-0
            "
          >
            <p class="text-left diary-text__title f-w6 mb-0">つぶやき</p>
            <div
              class="d-flex align-items-center"
              style="cursor: pointer"
              @click="
                $router.push({
                  name: 'CastTimeLineList',
                  query: {
                    userId: authUser.user_id
                  }
                })
              "
            >
              <!-- <p class="diary-text-more mb-0">もっと見る</p> -->
              <b-icon icon="chevron-right" />
            </div>
          </div>
        </div>
        <div
          class="vol"
          v-if="firebaseProfileTweets && firebaseProfileTweets.length > 0"
        >
          <!-- <p class="title text-left f-w6">つぶやき</p> -->
          <TimelineListProfile
            :propsAvatar="image_url_one"
            :propsNickname="user.nickname"
            :propsTimeline="firebaseProfileTweets"
            :propsIdAuth="authUser.user_id"
            @like="like"
          />
        </div>
      </div>
      <div class="footer-btn f-w6">
        <button
          class="button-submit"
          type="submit"
          @click="$router.push({ name: 'EditProfileCast' })"
        >
          プロフィール編集
        </button>
      </div>
      <CardFooter ref="footer" />
    </div>
  </div>
</template>
<script>
import UserSummary from "@/components/UserSummary.vue";
import CardFooter from "@/components/CardFooter.vue";
import CardHeader from "@/components/CardHeader.vue";
import { appearance, figure } from "@/constants/req";
import service from "@/utils/axios";
import { USER_URL } from "@/api/api";
import { mapGetters, mapActions } from "vuex";
import TimelineListProfile from "@/views/Common/Timeline/TimelineListProfile.vue";

export default {
  components: {
    UserSummary,
    CardFooter,
    CardHeader,
    TimelineListProfile
  },

  metaInfo() {
    return {
      title: "プロフィール",
      titleTemplate: "%s | PreGo"
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "common/isLoading",
      authUser: "auth/user",
      firebaseProfileTweets: "firebase/profileTweets"
    })
  },
  data() {
    return {
      query: this.$route.query,
      typeSingle: { type: "single" },
      typeScrollProfile: { type: "scroll-profile" },
      headerTitle: {
        text: "プロフィール",
        isShowTitle: true,
        isShowLogo: false
      },
      otherPreference: [],
      imageDetail: [],
      appearanceOption: appearance,
      figureOption: figure,
      isShowFullText: false,
      isShowFullTextBottom: false,
      user_id: null,
      user: null,
      usersSimilar: null,
      video_url: null,
      isShowVideo: false,
      isShowIconPlayVideo: true,
      image_url_two: null,
      image_url_one: null,
      isShowImage: false,
      image_full_url: null,
      listTimeLine: [
        {
          image: [
            { image_url: null },
            { image_url: null },
            { image_url: null }
          ],
          content:
            "ブログの本文、ブログの本文 ブログの本文、ブログの本文 ブログの本文、ブログの本文",
          id: 1
        },
        {
          image: [
            { image_url: null },
            { image_url: null },
            { image_url: null }
          ],
          content:
            "ブログの本文、ブログの本文 ブログの本文、ブログの本文 ブログの本文、ブログの本文",
          id: 2
        },
        {
          image: [
            { image_url: null },
            { image_url: null },
            { image_url: null }
          ],
          content:
            "ブログの本文、ブログの本文 ブログの本文、ブログの本文 ブログの本文、ブログの本文",
          id: 3
        },
        {
          image: [
            { image_url: null },
            { image_url: null },
            { image_url: null }
          ],
          content:
            "ブログの本文、ブログの本文 ブログの本文、ブログの本文 ブログの本文、ブログの本文",
          id: 4
        }
      ]
    };
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    await this.getInfoUser();
    await this.getProfileTweets({ userId: this.authUser.user_id });
    this.$store.dispatch("common/setIsLoading", false);
  },
  mounted() {
    document.getElementById("view-profile-cast").scrollIntoView();
  },
  methods: {
    ...mapActions("firebase", ["getProfileTweets", "likeTweet"]),
    async like(e) {
      let action = "like";
      if (e.is_like) {
        action = "unlike";
      }
      this.likeTweet({
        userId: this.authUser.user_id,
        tweetId: e.id,
        action: action
      });
      await this.getProfileTweets({ userId: this.authUser.user_id });
    },

    changeLikeStatusUserSimilar(data) {
      service({
        url: USER_URL + "/favorite",
        method: "POST",
        data: {
          user_id: data.userId,
          is_favorite: data.isFavorite === 1 ? 0 : 1
        }
      }).then(response => {
        const item = this.usersSimilar.find(
          item => item.user_id === data.userId
        );
        if (response.data.success === true) {
          item.is_favorite = data.isFavorite === 1 ? 0 : 1;
        }
      });
    },
    showFullText(index) {
      if (index == 0) {
        this.isShowFullText = !this.isShowFullText;
        return;
      }

      this.isShowFullTextBottom = !this.isShowFullTextBottom;
    },
    compactText(text) {
      let textStr = String(text);
      if (text.length > this.getNumberCharacters()) {
        return textStr.substr(0, this.getNumberCharacters()) + "...";
      }

      return text;
    },
    getNumberCharacters() {
      let characters = 0;
      if (window.innerWidth < 992)
        characters = Math.floor(43 + (window.innerWidth - 375) / 8);
      else characters = Math.floor(330 / 8);
      return characters;
    },
    formatText(text) {
      for (let i = 0; i <= text.length; i++) {
        if (text[i] == "~") {
          const text_format =
            text.substr(0, i) +
            "回" +
            " ~ " +
            text.substr(i + 1, text.length) +
            "回";
          return text_format;
        }
      }
    },
    formatTextArea(text) {
      let textArea = "";
      if (text.length >= 2) {
        for (let i = 0; i < text.length; i++) {
          if (i == text.length - 1) {
            textArea += text[i];
          } else {
            textArea += text[i] + "、";
          }
        }
      } else {
        textArea += text[0];
      }
      return textArea;
    },
    async getInfoUser() {
      const userInfo = this.$store.getters["auth/user"];
      await this.$store.dispatch("userApp/getInfoUser", userInfo.user_id);
      this.user = this.$store.getters["userApp/user"].user;
      this.user.area = null;
      if (this.user.user_golf_areas.length > 0) {
        this.user.area = this.formatTextArea(this.user.user_golf_areas);
      }
      this.imageDetail = [];
      for (let i = 0; i < this.user.image_urls.length; i++) {
        if (this.user.image_urls[i].type == 1) {
          if (this.user.image_urls[i].display_order == 1) {
            this.user.image_url = this.user.image_urls[i].image_url;
            this.image_url_one = this.user.image_urls[i].image_url;
          } else if (this.user.image_urls[i].display_order == 2) {
            this.image_url_two = this.user.image_urls[i].image_url;
          } else {
            this.imageDetail.push(this.user.image_urls[i]);
          }
        }
        if (this.user.image_urls[i].type == 3) {
          this.video_url = this.user.image_urls[i].image_url;
        }
      }
      this.usersSimilar = this.user.similar_users;
      if (this.user.golf_frequency != null) {
        this.user.golf_frequency = this.formatText(this.user.golf_frequency);
      }
      this.user.isShowButton = true;
    },
    changeImage(image_url) {
      this.isShowVideo = false;
      this.isShowImage = false;
      this.user.image_url = image_url;
      this.$store.state.userApp.image_url = image_url;
      this.isShowIconPlayVideo = true;
    },
    changeFullImage(url) {
      this.isShowImage = true;
      this.isShowVideo = false;
      this.isShowIconPlayVideo = true;
      this.image_full_url = url;
    },
    showVideo() {
      this.isShowVideo = true;
      this.isShowImage = false;
    },
    playVideo() {
      const video = document.getElementById("video");
      if (video.paused || video.ended) {
        video.play();
        this.isShowIconPlayVideo = false;
      } else {
        video.pause();
        this.isShowIconPlayVideo = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cast/profileView.scss";
@import "@/assets/scss/_fontFamily.scss";

.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 57px;
  padding-bottom: 150px;
  // height: calc(100vh - 108px);
}
.footer-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 337px;
  height: 44px;
  position: fixed;
  z-index: 10;
  bottom: 84px;
  left: 0;
  right: 0;
  .button-submit {
    width: 100%;
    max-width: 337px;
    height: 45px;
    background-color: #464d77;
    border-radius: 23px;
    color: $white;
    position: relative;
    z-index: 3;
    border: 0px 5x 10px #a2a2a2;
  }
}

@media screen and (min-width: 1200px) {
  .content-scroll {
    padding-top: 100px;
    padding-bottom: 250px;
    // height: calc(100vh - 152px);
  }
  .footer-btn {
    max-width: 425px;
    height: 62px;
    position: fixed;
    z-index: 10;
    bottom: 106px;
    left: 0;
    right: 0;
    .button-submit {
      width: 100%;
      max-width: 425px;
      height: 62px;
      border-radius: 27px;
      font-size: 20px;
      position: relative;
      z-index: 3;
    }
  }
}
</style>
